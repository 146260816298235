<template>
  <main class="pt2020">
    <b-row class="main-section kh-bg-secondary">
      <b-container>
        <b-row class="mb-5" no-gutters>
          <b-img class="mr-4" src="@/assets/img/pt2020-01.svg" height="64"/>
          <b-img class="mr-4" src="@/assets/img/pt2020-02.svg" height="64"/>
          <b-img class="mr-4" src="@/assets/img/pt2020-03.svg" height="64"/>
        </b-row>
        <b-link class="custom-link" :to="{ name: 'project-sheet-idt' }">View Kinetikos 4PD</b-link>
      </b-container>
    </b-row>

    <b-row class="main-section">
      <b-container>
        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Designação do projeto</b-col>
          <b-col>Kinetikos Internacional</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Código do projeto</b-col>
          <b-col>CENTRO-02-0752-FEDER-043852</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Objetivo principal</b-col>
          <b-col>Reforçar a competitividade das PME</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Região de intervenção</b-col>
          <b-col>Centro</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Entidade beneficiária</b-col>
          <b-col>KINETIKOS - DRIVEN SOLUTIONS LDA</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Data da aprovação</b-col>
          <b-col>15-07-2019</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Data de início</b-col>
          <b-col>01-10-2019</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Data de conclusão</b-col>
          <b-col>30-11-2022</b-col>
        </b-row>

        <b-row class="mb-5 mb-md-3">
          <b-col md="3" class="h5 font-weight-bold">Custo total elegível</b-col>
          <b-col>329.533,40 €</b-col>
        </b-row>

        <b-row>
          <b-col md="3" class="h5 font-weight-bold">Apoio financeiro da União Europeia</b-col>
          <b-col>148.290,03 €</b-col>
        </b-row>
      </b-container>
    </b-row>

    <b-row class="main-section kh-bg-secondary pt2020-section">
      <b-container>
        <b-row>
          <b-col md="8">
            <h3>Síntese do projeto</h3>

            <h4>Objetivos</h4>
            <p>O projeto Kinetikos Internacional visa capitalizar os importantes investimentos em I&amp;D feitos recentemente pela empresa nos seus produtos CliniK e PDtraKer, através da diversificação dos mercados internacionais, apostando em gerar tração em 3 mercados: Reino Unido, Espanha e EUA.</p>

            <h4 class="mt-5">Atividades</h4>
            <p>A Kinetikos irá:</p>
            <ul>
              <li>Aumentar o seu conhecimento de mercados externos</li>
              <li>Apostar numa estratégia de marketing digital</li>
              <li>Fazer prospecção e captação de novos clientes</li>
              <li>Introduzir novas metodologias nas práticas comerciais e relações externas</li>
            </ul>

            <h4 class="mt-5">Resultados esperados</h4>
            <p>Espera-se alcançar:</p>
            <ul>
              <li>Uma taxa de exportações superior a 80% em 2022</li>
              <li>Entrar sustentadamente nos mercados de Reino Unido e Espanha em 2019/2020 e nos EUA ate 2021</li>
              <li>Atingir 250.000 sessões clínicas registadas na plataforma Kinetikos em 2022</li>
              <li>Atingir um VN superior a 5,4 M€ em 2022 e um EBITDA correspondente a 50%</li>
              <li>Aumentar a visibilidade da Empresa</li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </main>
</template>

<script>
export default {
  name: 'project-sheet-idt'
}
</script>
